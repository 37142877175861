import { projectApi } from 'api'

export interface Integration {
  type: string
  available: boolean
}

interface Response {
  data: Integration[]
}

export const fetchIntegrationsApi = () => projectApi.get<Response>('integrations/available')
